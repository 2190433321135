import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// Material-ui
import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// Project imports
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import useLayoutState from 'hooks/useLayoutState';
import { drawerWidth } from 'themes/constants';

// Styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    height: 'calc(100vh - 75px)',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    marginTop: '75px',
    width: `calc(100% - ${drawerWidth}px)`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -drawerWidth,
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '72px',
        },
        width: '100%',
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter,
        }),
        paddingLeft: '30px',
    }),
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const { isSidebarDrawerOpen, setIsSidebarDrawerOpen } = useLayoutState();

    useEffect(() => {
        setIsSidebarDrawerOpen(!matchDownMd);
    }, [matchDownMd, setIsSidebarDrawerOpen]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Header />

            {/* drawer */}
            <Sidebar />

            {/* main content */}
            <Main theme={theme} open={isSidebarDrawerOpen}>
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
