import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function FilterSection({ children, sx = {} }) {
    FilterSection.propTypes = {
        children: PropTypes.node.isRequired,
        sx: PropTypes.object,
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ...sx }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FilterListIcon sx={{ mr: 1 }} />
                <Typography>Filters:</Typography>
            </Box>
            {children}
        </Box>
    );
}
