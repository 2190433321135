import { TableContainer } from '@mui/material';
import PropTypes from 'prop-types';

export default function TableContainerWithScrollShadow({ sx = {}, children, ...rest }) {
    TableContainerWithScrollShadow.propTypes = {
        children: PropTypes.node.isRequired,
        sx: PropTypes.object,
    };

    const defaultTableContainerShadowStyles = {
        border: 1,
        borderColor: 'grey.300',
        backgroundImage: `
            linear-gradient(to right, white, white),
            linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0,0,0,0.3), rgba(255,255,255,0)),
            linear-gradient(to left, rgba(0,0,0,0.3), rgba(255,255,255,0))
        `,
        backgroundPosition: 'left center, right center, left center, right center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: '20px 100%, 20px 100%, 10px 100%, 25px 100%',
        backgroundAttachment: 'local, local, scroll, scroll',
    };

    return (
        <TableContainer sx={{ ...defaultTableContainerShadowStyles, ...sx }} {...rest}>
            {children}
        </TableContainer>
    );
}
