import { ENVIRONMENTS } from '@cta-pond/constants';

export const GOOGLE = {
    scopes: import.meta.env.VITE_GOOGLE_API_SCOPES?.split(',') || [],
    discoveryDocs: import.meta.env.VITE_GOOGLE_DISCOVERY_DOCS?.split(',') || [],
};

export const FIREBASE_API = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    // All parts of this config field are optional.
    // if none of the inputs are set, it's an empty string which means the API is hosted at same domain
    // (i.e in hosted emulator, PR preview, or live)
    baseApiHostPath: `${import.meta.env.VITE_BASE_API_PROTOCOL?.concat('://') ?? ''}${import.meta.env.VITE_BASE_API_HOST ?? ''}${
        import.meta.env.VITE_BASE_API_PATH ?? ''
    }`,
    remoteConfigMinimumFetchIntervalMs: parseInt(import.meta.env.VITE_FIREBASE_REMOTE_CONFIG_MINIMUM_FETCH_INTERVAL_MS),
    appCheckRecaptchaSiteKey: import.meta.env.VITE_FIREBASE_APP_CHECK_RECAPTCHA_SITE_KEY,
};

export const SENTRY = {
    releaseId: import.meta.env.VITE_SENTRY_RELEASE_ID || '',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    sampleRate: parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE) || 1.0,
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 1.0),
    tracePropagationTargets: import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS || '',
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.1,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 1.0,
};

// Basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

const config = {
    fontFamily: `'Inter','Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // Light, dark
    presetColor: 'theme1', // Default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false,
    env: import.meta.env.VITE_ENVIRONMENT || ENVIRONMENTS.LOCAL,
    showErrors: import.meta.env.VITE_SHOW_ERRORS === 'true',
    version: import.meta.env.VITE_VERSION,
};

export default config;
