import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './Error.css';

function ErrorComponent() {
    const mailto =
        "mailto:help@techallies.org?subject=PAD UI Problem&body=Please describe the PAD UI problem you're experiencing:";

    return (
        <div className="root">
            <div className="header">
                <ReportOutlinedIcon className="icon" />
                <Typography component="span" variant="h1">
                    Sorry, something went wrong...
                </Typography>
            </div>
            <div className="actions">
                <div>
                    <Button className="button" href="/" variant="outline">
                        Reload
                    </Button>
                </div>
                <Button component="a" href={mailto} className="button">
                    Report this error
                </Button>
            </div>
        </div>
    );
}

export default ErrorComponent;
