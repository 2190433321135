import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function BrowserTimezoneAlert({ sx = {}, ...rest }) {
    BrowserTimezoneAlert.propTypes = {
        sx: PropTypes.object,
    };

    const [isTzAlertVisible, setIsTzAlertVisible] = useState(true);

    const browserNow = new Date();

    const currentTzAcronym = browserNow.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
    const currentTzVerbose = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        isTzAlertVisible && (
            <Alert
                square
                severity="info"
                variant="outlined"
                sx={sx}
                onClose={() => setIsTzAlertVisible(false)}
                {...rest}
            >
                All times are displayed in your browser timezone: {currentTzAcronym} ({currentTzVerbose})
            </Alert>
        )
    );
}
