import { SYNC_FIELDS } from '@cta-pond/constants';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import BrowserTimezoneAlert from 'components/BrowserTimezoneAlert';
import FilterSection from 'components/FilterSection';
import LoadingStateProgressBar from 'components/LoadingStateProgressBar';
import PageHeader from 'components/PageHeader';
import SearchParamControlledAutocomplete from 'components/SearchParamControlledAutocomplete';
import StickyTableCell from 'components/StickyTableCell';
import SyncRunStatus from 'components/SyncRunStatus';
import TableContainerWithScrollShadow from 'components/TableContainerWithScrollShadow';
import TypographyOverflow from 'components/TypographyOverflow';
import { getCronDescriptionWithTzOffset } from 'helpers/cron';
import { convertFirestoreTimestampToDate } from 'helpers/firestore';
import { useLoaderData } from 'react-router-dom';

export default function SyncSchedulePage() {
    const { syncs, allPartnerIds, allVendorNames } = useLoaderData();

    const headerCellStyles = {
        minWidth: 200,
        zIndex: 1,
    };

    return (
        <>
            <PageHeader title="Sync Schedule" subtitle="View sync details across partners and platforms" />
            <Box component="section" sx={{ display: 'flex', flexFlow: 'column', height: '85vh' }}>
                <BrowserTimezoneAlert sx={{ mb: 2 }} />
                <FilterSection sx={{ mb: 2 }}>
                    <SearchParamControlledAutocomplete
                        searchParam={SYNC_FIELDS.PARTNER_ID}
                        label="Partner ID"
                        options={allPartnerIds}
                    />
                    <SearchParamControlledAutocomplete
                        searchParam={SYNC_FIELDS.VENDOR_NAME}
                        label="Source"
                        options={allVendorNames}
                    />
                </FilterSection>
                <LoadingStateProgressBar />
                <TableContainerWithScrollShadow sx={{ flex: 1 }}>
                    <Table aria-label="Syncs table" size="small" stickyHeader sx={{ whiteSpace: 'nowrap' }}>
                        <TableHead>
                            <TableRow>
                                <StickyTableCell>Partner ID</StickyTableCell>
                                <TableCell sx={headerCellStyles}>Source</TableCell>
                                <TableCell sx={headerCellStyles}>Name</TableCell>
                                <TableCell sx={headerCellStyles}>Schedule</TableCell>
                                <TableCell sx={headerCellStyles}>Status</TableCell>
                                <TableCell sx={headerCellStyles}>Last run end</TableCell>
                                <TableCell sx={headerCellStyles}>Next run</TableCell>
                                <TableCell sx={headerCellStyles}>Method</TableCell>
                                <TableCell sx={{ ...headerCellStyles, minWidth: 400 }}>Sync ID</TableCell>
                                <TableCell sx={headerCellStyles}>Tags</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {syncs.map((sync) => (
                                <TableRow key={sync.id}>
                                    <StickyTableCell>{sync.partnerName ?? '-'}</StickyTableCell>
                                    <TableCell>{sync.vendorName ?? '-'}</TableCell>
                                    <TableCell>{sync.syncName ?? '-'}</TableCell>
                                    <TableCell>
                                        <TypographyOverflow width={150}>
                                            {sync.scheduleInterval
                                                ? getCronDescriptionWithTzOffset(sync.scheduleInterval)
                                                : 'No schedule provided'}
                                        </TypographyOverflow>
                                    </TableCell>
                                    <TableCell>
                                        <SyncRunStatus status={sync.lastState} />
                                    </TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.lastEndDate)}</TableCell>
                                    <TableCell>
                                        {sync.isPaused ? 'Paused' : convertFirestoreTimestampToDate(sync.nextDAGRun)}
                                    </TableCell>
                                    <TableCell>{sync.method}</TableCell>
                                    <TableCell>{sync.id}</TableCell>
                                    <TableCell>
                                        <TypographyOverflow width={200}>
                                            {sync.tags?.map((tag) => tag.name)?.join(', ') ?? '-'}
                                        </TypographyOverflow>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainerWithScrollShadow>
            </Box>
        </>
    );
}
