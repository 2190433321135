import cronstrue from 'cronstrue';

export function getCronDescriptionWithTzOffset(scheduleInterval) {
    try {
        const browserNow = new Date();
        const tzOffsetInHours = browserNow.getTimezoneOffset() / 60;
        const tzOffset = 0 - tzOffsetInHours;

        let description = cronstrue.toString(scheduleInterval, { verbose: true, tzOffset });
        if (description.startsWith('At ')) {
            description = description.replace('At ', '');
        }
        return description;
    } catch (err) {
        return 'Invalid cron expression';
    }
}
