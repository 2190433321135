import { SYNC_FIELDS } from '@cta-pond/constants';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

export default function SearchParamControlledAutocomplete({ searchParam, label, ...rest }) {
    SearchParamControlledAutocomplete.propTypes = {
        searchParam: PropTypes.oneOf(Object.values(SYNC_FIELDS)).isRequired,
        label: PropTypes.string.isRequired,
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const onChangeFilter = (key, newValue) => {
        const { [key]: currentValue, ...otherSearchParams } = Object.fromEntries(searchParams);

        if (newValue) {
            setSearchParams({ ...otherSearchParams, [key]: newValue });
        } else {
            setSearchParams(otherSearchParams);
        }
    };

    return (
        <Autocomplete
            id={`${searchParam}-autocomplete`}
            value={searchParams.get(searchParam)}
            onChange={(_, val) => onChangeFilter(searchParam, val)}
            renderInput={(params) => <TextField {...params} label={label} size="small" sx={{ minWidth: 200 }} />}
            {...rest}
        />
    );
}
