import BackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DemoLabel from 'components/DemoLabel';

export default function PageHeader({
    title,
    subtitle = null,
    action = null,
    showDemoLabel = false,
    goBackRoute = null,
}) {
    PageHeader.propTypes = {
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        action: PropTypes.object,
        showDemoLabel: PropTypes.bool,
        goBackRoute: PropTypes.string,
    };

    const showBackButton = Boolean(goBackRoute);
    const maybeExtraWrapperClassesForBackButton = showBackButton ? {} : {};

    return (
        <Box
            component="header"
            sx={{ display: 'flex', alignItems: 'center', mb: 1, ...maybeExtraWrapperClassesForBackButton }}
        >
            {showBackButton && (
                <IconButton component={Link} to={goBackRoute} sx={{ ml: -2, mr: 1, py: 0 }}>
                    <BackIcon fontSize="large" />
                </IconButton>
            )}
            <Box>
                <Typography variant="h1" sx={{ display: 'flex', alignItems: 'center', my: 0 }}>
                    {title}
                    {showDemoLabel && <DemoLabel />}
                </Typography>
                {subtitle && (
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        {subtitle}
                    </Typography>
                )}
            </Box>
            {action && <Box sx={{ ml: 'auto' }}>{action}</Box>}
        </Box>
    );
}
