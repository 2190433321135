import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function TypographyOverflow({ children, width }) {
    TypographyOverflow.propTypes = {
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        width: PropTypes.number.isRequired,
    };

    return (
        <Tooltip title={children} placement="top">
            <Typography
                sx={{
                    textWrap: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'hidden',
                    width,
                }}
                component="div"
            >
                {children}
            </Typography>
        </Tooltip>
    );
}
