import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';

export default function StickyTableCell({ children, sx = {}, ...rest }) {
    StickyTableCell.propTypes = {
        children: PropTypes.node.isRequired,
        sx: PropTypes.object,
    };

    return (
        <TableCell
            sx={{
                position: 'sticky',
                left: 0,
                minWidth: 250,
                backgroundColor: 'white',
                borderRight: '2px solid',
                borderRightColor: 'grey.300',
                ...sx,
            }}
            {...rest}
        >
            {children}
        </TableCell>
    );
}
