import config from 'config';
import PropTypes from 'prop-types';

import Error from './Error';

import './Error.css';

const ErrorBoundary = function ({ error = null }) {
    const showErrors = Boolean(config.env === 'local' && config.showErrors);

    if (showErrors) {
        return (
            <div className="root">
                <h1 className="header">Something went wrong...sorry.</h1>
                <hr />
                <div>
                    <h2>{error && error.toString()}</h2>
                    <h2>Where it occurred:</h2>
                    <pre>{error && error.stack}</pre>
                </div>
            </div>
        );
    }

    return <Error error="Oops, something went wrong" />;
};

ErrorBoundary.propTypes = {
    error: PropTypes.object,
};

export default ErrorBoundary;
