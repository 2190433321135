import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useLoaderData } from 'react-router-dom';

import { SYNC_FIELDS } from '@cta-pond/constants';
import BrowserTimezoneAlert from 'components/BrowserTimezoneAlert';
import EmptyStatePlaceholder from 'components/EmptyStatePlaceholder';
import FilterSection from 'components/FilterSection';
import LoadingStateProgressBar from 'components/LoadingStateProgressBar';
import PageHeader from 'components/PageHeader';
import SearchParamControlledAutocomplete from 'components/SearchParamControlledAutocomplete';
import StickyTableCell from 'components/StickyTableCell';
import SyncRunStatus from 'components/SyncRunStatus';
import TableContainerWithScrollShadow from 'components/TableContainerWithScrollShadow';
import { getCronDescriptionWithTzOffset } from 'helpers/cron';
import { convertFirestoreTimestampToDate } from 'helpers/firestore';

export default function ManagedSyncsIndexPage() {
    const { syncs, allVendorNames } = useLoaderData();

    const doesProjectHaveSyncs = Boolean(syncs?.length);

    const headerCellStyles = {
        minWidth: 200,
        zIndex: 1,
    };

    return (
        <>
            <PageHeader title="Managed Syncs" subtitle="Syncs managed and created by CTA for this project" />
            <Box
                component="section"
                sx={{ pt: 1, flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
            >
                <BrowserTimezoneAlert sx={{ mb: 2 }} />
                <FilterSection sx={{ mb: 1, zIndex: 2 }}>
                    <SearchParamControlledAutocomplete
                        searchParam={SYNC_FIELDS.VENDOR_NAME}
                        label="Source"
                        options={allVendorNames ?? []}
                    />
                </FilterSection>
                <LoadingStateProgressBar />
                {doesProjectHaveSyncs ? (
                    <TableContainerWithScrollShadow sx={{ flex: 1 }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="Syncs table">
                            <TableHead>
                                <TableRow>
                                    <StickyTableCell>Source</StickyTableCell>
                                    <TableCell sx={headerCellStyles}>Schedule</TableCell>
                                    <TableCell sx={headerCellStyles}>Last run status</TableCell>
                                    <TableCell sx={headerCellStyles}>Last run end</TableCell>
                                    <TableCell sx={headerCellStyles}>Next run</TableCell>
                                    <TableCell sx={{ ...headerCellStyles, minWidth: 400 }}>Sync ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {syncs.map((sync) => (
                                    <TableRow key={sync.id}>
                                        <StickyTableCell>{sync.vendorName ?? '-'}</StickyTableCell>
                                        <TableCell>
                                            {sync.scheduleInterval
                                                ? getCronDescriptionWithTzOffset(sync.scheduleInterval)
                                                : 'No schedule provided'}
                                        </TableCell>
                                        <TableCell>
                                            <SyncRunStatus status={sync.lastState} />
                                        </TableCell>
                                        <TableCell>{convertFirestoreTimestampToDate(sync.lastEndDate)}</TableCell>
                                        <TableCell>{convertFirestoreTimestampToDate(sync.nextDAGRun)}</TableCell>
                                        <TableCell>{sync.id}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainerWithScrollShadow>
                ) : (
                    <EmptyStatePlaceholder
                        title="No syncs to show"
                        subtitle="Try adjusting your filters above. Otherwise, CTA-managed syncs will appear here after they are set up."
                    />
                )}
            </Box>
        </>
    );
}
